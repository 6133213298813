<template>
    <div class='relative'>
        <svg class='mt-5' fill="none" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1360 74"><path d="m0 3.9073 60 1.9349c60 2.0808 180 5.7315 300 3.9062C480 7.923 600-.1084 720 .001c120-.1095 240 7.922 360 9.7473 120 1.8253 240-1.8254 300-3.9062l60-1.9349V74H0V3.9073Z" fill="#0B2B31"/></svg> 

        <div class="bg-custom-blue py-5 relative bottom-1 shadow-lg">
            <div class="container mx-auto w-11/12">
                <!-- Sobre Mi -->
                <h1 class='text-white text-2xl text-center' >Sobre Mi</h1>
                <div class="blue-card p-8 w-full gap-x-8 md:gap-y-0 gap-y-5 mt-5 justify-center items-center md:flex-nowrap flex-wrap">
                    <img class='rounded-full shadow h-52' src="../assets/img/perfil-recortado.jpeg" alt="Mauro Arias">
                    <div class='sm:mx-14 mx-2 flex flex-col'>
                        <p class='text-custom-gray text-justify'>Me apasiona la tecnología y el desarrollo de sitios web, actualmente estoy enfocado al front end pero también tengo conocimientos del back end. A día de hoy curso la carrera de ingeniería en sistemas.
                        <br><br>Siempre me gusta aprender tecnologías nuevas e ir ganando experiencia en el área.
                        </p>
                        <p class='text-custom-gray text-sm mt-5 md:text-left text-center'>
                            <i class="fa-solid fa-language text-base mr-1"></i> Español nativo - Inglés intermedio
                        </p>
                        
                        <div class='mt-4 flex items-center sm:flex-row flex-col flex-wrap gap-x-2 gap-y-2'>
                            <a target='_blank' href='https://github.com/mauro-arias' class='primary-btn md:w-32 w-full text-center p-2'><i class="ai-github-fill mr-2 "></i>GitHub</a>
                            <a target='_blank' href='https://www.linkedin.com/in/mauro-arias-charras/' class='primary-btn md:w-32 w-full text-center p-2'><i class="ai-linkedin-box-fill mr-2 "></i>LinkedIn</a>
                            <a target='_blank' href='https://api.whatsapp.com/send?phone=543512333859' class='primary-btn md:w-32 w-full text-center p-2'><i class="ai-whatsapp-fill mr-2 "></i>WhatsApp</a>
                            <a target="_blank" href="/mauroarias-cv.pdf" class='primary-btn md:w-32 w-full text-center p-2'><i class="ai-file mr-1"></i>Currículum</a>

                        </div>
                    </div>
                </div>
                <!-- Tecnologías -->
                <h1 class='text-white text-2xl text-center mt-5'>Tecnologías</h1>
                <div class="container w-11/12 mx-auto flex gap-x-5 gap-y-5 justify-center items-center flex-wrap mt-5">
                    <Technology v-for="(technologie, index) in this.technologies" :key="index" :name='technologie.name' :imgUrl="require(`../assets/${technologie.imgUrl}`)"/>
                </div>
            </div>
        </div>
        <Experience />
        <Studies />
    </div>

</template>

<script>
import Technology from '@/components/Technology.vue'
import Experience from '@/components/Experience.vue'
import Studies from '@/components/Studies.vue'
import technologies from '@/data/technologies.json'

export default {
    name: 'About',
    components: {
        Technology,
        Experience,
        Studies
    },
    data(){
        return {
            technologies
        }
    }
}
</script>

<style scoped>
    .wave1{
        line-height: 0; 
        display: block;
        shape-rendering: crispEdges;
    }
</style>