<template>
    <div class='mt-5 container mx-auto mb-2' v-if="enabled">
        <h1 class='text-custom-blue text-2xl text-center'>Experiencia laboral</h1>
        <Work v-for="(work, index) in this.works" :key="index" :work="work" />
    </div>
</template>

<script>
import Work from './Work.vue'
import works from '../data/works.json'

export default {
    name: 'Experience',
    components: {
        Work
    },
    data(){
        return {
            enabled: false,
            works
        }
    }
}
</script>

<style scoped>
    
</style>