<template>
    <svg class='mt-5 block' fill="none" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1360 74"><path d="m0 59.2266 48-7.3867c48-7.3867 144-22.1601 240-34.4866C384 5.2113 480-4.9453 576 2.5799c96 7.2482 192 32.1783 288 39.4265 96 7.5252 192-2.6315 288 0 96 2.4468 192 17.2202 240 24.6069L1440 74H0V59.2266Z" fill="#0B2B31"/></svg>
    <div class="bg-custom-blue py-5 shadow-lg">
        <div class="container mx-auto">
            <h1 class='text-white text-2xl text-center' >Contacto</h1>
            <div class="flex justify-center items-center mt-5 flex-col gap-y-2">
                <p class='text-custom-gray text-lg'><i class="fa-solid fa-paper-plane text-custom-gray text-lg mr-2"></i>¡Hablemos!</p>
                <div>
                    <p class='text-custom-gray text-center'>¡Estoy disponible y dispuesto a escuchar tu propuesta!</p>
                </div>
            </div>
            
            <form @submit="handleSubmit" class='flex justify-center items-center mt-5 flex-col gap-y-3'>
                <input v-model="this.nombre" placeholder='Nombre' type="text" class='input md:w-1/2 w-5/6 p-2'>
                <input v-model="this.correo" placeholder='Correo' type="email" class='input md:w-1/2 w-5/6 p-2'>
                <input v-model="this.asunto" placeholder='Asunto' type="text" class='input md:w-1/2 w-5/6 p-2'>
                <textarea v-model="this.mensaje" placeholder='Mensaje' rows="10"  type="text" class='input md:w-1/2 w-5/6 p-2'></textarea>

                <p v-if="this.error" class='text-sm text-red-600 text-center'><i class="ai-chat-error mr-2"></i>Por favor, ingrese todos los campos.</p>
                
                <button :disabled='this.enviado' :class='[this.enviado ? "disabled" : "","secondary-btn", "w-36", "h-9", "px-3", "py-1", "font-medium", "flex", "justify-center", "items-center"]' type='submit'><i v-if='this.enviado === false' class="ai-send mr-2 text-lg"></i><div v-if="this.enviado" class="loader mr-2"></div>{{this.enviado ? "Enviando..." : "Enviar"}}</button>
            </form>

        </div>
    </div>

</template>

<script>
export default {
    name: 'Contact',
    data(){
        return {
            nombre: '',
            correo: '',
            asunto: '',
            mensaje: '',
            error: false,
            enviado: false
        }
    },
    methods: {
        handleSubmit(e){
            e.preventDefault();
            if(this.correo === '' || this.asunto === '' || this.mensaje === '' || this.nombre === ''){
                this.showError()
            } else {
                // Envio de correo
                this.error = false
                this.enviado = true
                const params = {
                    nombre: this.nombre,
                    correo: this.correo,
                    asunto: this.asunto,
                    mensaje: this.mensaje
                }
                emailjs.send('service_ptofofc', 'template_bqsz77d', params).then(() => {
                    this.$router.push({name: 'Success'})
                })
            }
        },
        showError(){
            this.error = true
            setTimeout(() => this.error = false, 5000);
        }
    },
    mounted(){
        emailjs.init("user_TFKZJ0Szcecsj1mL0vhry");
    }
}
</script>

<style scoped>
    textarea{
        resize: none;
    }
    .disabled{
        background-color: #072227;
        color: #7C99AC;
        box-shadow: none;
    }

    .loader {
        border: 2px solid #646464; /* Light grey */
        border-top: 2px solid #7C99AC; /* Blue */
        border-radius: 50%;
        width: 20px;
        height: 20px;
        animation: spin 2s linear infinite;
    }

    @keyframes spin {
        0% { transform: rotate(0deg); }
        100% { transform: rotate(360deg); }
    }
</style>