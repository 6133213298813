<template>
    <div class='bg-custom-blue flex sm:flex-row flex-col rounded-xl sm:h-44 h-80 shadow-xl mt-5 overflow-hidden'>
        <div class='sm:w-44 w-full bg-custom-darkblue flex-col flex justify-center items-center'>
            <h1 class='text-custom-gray sm:mt-0 mt-2 text-sm'>{{ work.company }}</h1>
            <img class='h-24' :src="require(`../assets/${work.imgUrl}`)" alt="">
        </div>
        <div class='flex flex-col sm:m-2 m-1 w-full justify-evenly'>
            <h1 class='text-white text-lg sm:text-left text-center'>{{work.position}}</h1>
            <p class='text-custom-gray text-xs sm:text-left text-center'>{{work.timelapse}}</p>
            <div class='flex justify-center items-center mr-5 sm:h-44 h-32 '>
                <p class='text-custom-gray sm:text-justify text-center sm:text-sm text-xs'>{{work.description}}</p>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: 'Work',
    props: {
        work: Object
    }
}
</script>

<style>

</style>