<template>
    <div class='mt-5 container w-11/12 mx-auto mb-2'>
        <h1 class='text-custom-blue text-2xl text-center'>Estudios</h1>
        <div class="flex flex-wrap justify-center items-center gap-x-2 gap-y-2 mt-5">
            <Studie v-for="(studie,index) in this.studies" :key="index" :studie="studie"/>
        </div>
    </div>
</template>

<script>
import Studie from './Studie.vue'
import studies from '../data/studies.json'

export default {
    name: 'Studies',
    components: {
        Studie
    },
    data(){
        return {
            studies
        }
    }
}
</script>

<style scoped>
    .card{
        width: 400px;
    }

</style>