<template>
    <div class="blue-card px-6 py-4 hover:-translate-y-2 ease-in duration-100 transform w-32 flex flex-col items-center justify-center hover:border-custom-lightblue border-2 border-transparent">
        <img class='text-custom-lightblue' :src="imgUrl" :alt="name" style='filter: invert(60%) sepia(10%) saturate(832%) hue-rotate(161deg) brightness(97%) contrast(86%);'>
        <h1 class='text-custom-gray mt-2 text-sm'>{{name}}</h1>
    </div>
</template>

<script>
export default {
    name: 'Technology',
    props: {
        name: String,
        imgUrl: String
    }
}
</script>

<style>

</style>