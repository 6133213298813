<template>
    <div class='mt-10 container w-11/12 mx-auto mb-2' id="projects">
        <h1 class='text-custom-blue text-2xl text-center'>Proyectos</h1>
        <div class="flex flex-wrap justify-center gap-x-2 gap-y-2 items-center mt-5">
            <ProjectCard v-for='(project,index) in this.projects' :key='index' :name="project.name" :description="project.description" :imgUrl="require(`../assets/${project.imgUrl}`)" :technologies="project.technologies" :siteUrl="project.siteUrl" :githubUrl="project.githubUrl" :hasRepo="project.hasRepo" :isOnline="project.isOnline"/>
        </div>
    </div>
</template>

<script>
import projects from '@/data/projects.json'
import ProjectCard from '@/components/ProjectCard.vue'

export default {
    name: 'Projects',
    components: {
        ProjectCard
    },
    data(){
        return {
            projects
        }
    }
}
</script>