<template>
    <div class="container mx-auto h-screen portada flex flex-col items-center justify-evenly">
        <h1 class='text-2xl text-custom-darkblue text-center'>¡Correo enviado exitosamente!</h1>
        <img class='h-64' src="../assets/img/contacto-exito.svg" alt="Correo enviado">
        <router-link :to="{name: 'Home'}" class='secondary-btn w-44 p-2 flex justify-center items-center'><i class="ai-circle-check-fill mr-1"></i>Volver al inicio</router-link>
    </div>
</template>

<script>
export default {
    name: 'Success'
}
</script>

<style scoped>
    .portada{
        background-image: url("../assets/img/portada.svg");
        background-size: cover;
        background-position: center;
    }
</style>