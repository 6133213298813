<template>
    <div class="card w-44 flex flex-col overflow-hidden">
        <div class="bg-custom-blue items-center flex flex-col gap-y-2 items-center py-2 justify-center">
            <img class='rounded-full h-20 shadow-lg' :src="require(`../assets/${studie.imgUrl}`)" :alt="studie.institute">
            <h2 class='text-white text-sm'>{{studie.institute}}</h2>
        </div>
        <div class="bg-white p-3">
            <h1 class='text-custom-darkblue font-medium text-center'>{{studie.name}}</h1>
            <p class='text-custom-blue text-sm text-center font-medium'>{{studie.timelapse}}</p>
            <div class="h-32 mt-1 flex items-center">
                <p class='text-sm text-custom-darkgray text-justify'>{{ studie.description }}</p>
            </div>
            <div class="flex justify-center mt-1">
                <a class='primary-btn w-44 flex justify-center items-center px-4 py-2' :href="studie.siteUrl" target="_blank"><i class="ai-globe mr-2"></i>Visitar institución</a>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: 'Studie',
    props: {
        studie: Object
    }
}
</script>

<style>

</style>